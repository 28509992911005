import React from 'react';
import PropTypes from 'prop-types';

const ItemHero = ({ item }) => {
  return (
    <div>This is the details page for: {item.name}</div>
  );
};

ItemHero.propTypes = {
  item: PropTypes.shape().isRequired
};

export default ItemHero;
