import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../layouts/Layout';
import PropTypes from 'prop-types';
import ItemHero from '../components/ItemHero';

const ItemTemplate = data => {
  const {pageContext: {item}} = data;
  return (
    <Layout>
      <div className="custom-container--large slds-container--center slds-p-top--large">
        <div className="slds-text-align--center Fz(24)">
          <ItemHero item={item}/>
        </div>
        <div>
          <div>{item.name}</div>
          <div>{item.description}</div>
        </div>
      </div>
    </Layout>
  );
};

export default ItemTemplate;
